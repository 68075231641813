import { loader } from "../../loader";
import { useEffect, useState } from "react";
import { db } from "../../config/firebaseConfig";
import { ENDPOINT } from "../../axios/apiConfig";
import { postData, getData } from "../../axios/apiHelper";
import { collection, query, where, onSnapshot } from "firebase/firestore";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import CommonPageLinkNotFound from "../CommonComponents/CommonPageLinkNotFound";
import LiveStreaming from "./LiveStreaming";
import WebinarPolls from "./WebinarPolls";
import { Col, Row } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
let path_image = process.env.REACT_APP_ASSETS_PATH_INFORMED_DESIGN;

const Stream = () => {
    const location = useLocation();
    const [searchParams] = useSearchParams();
    let parms = searchParams.get("evnt");
    const queryParams = new URLSearchParams(location.search);
    const [eventId, setEvent] = useState({
        id: 0,
        companyId: 0,
        code: queryParams.get("evnt")
    });

    const [firstPopupShow, setFirstPopupShow] = useState(false);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [cookieShow, setCookieShow] = useState(false);
    const [siteTrials, setSiteTrials] = useState({});
    const [isEventLive, setIsEventLive] = useState(true);

    const hideCookieModal = () => {
        setCookieShow(false);
        if (eventId?.id != 0) {
            const localData = "event_" + eventId?.id;
            if ((localStorage?.getItem(localData) == '' || localStorage?.getItem(localData) == null) && isEventLive) {
                setFirstPopupShow(true);
            }
        }
    }

    const showCookieModal = () => {
        setFirstPopupShow(false);
        setCookieShow(true);
    }

    useEffect(() => {
        EventDataFun();
    }, []);

    const EventDataFun = async () => {
        try {
            loader("show");
            const result = await postData(ENDPOINT.EVENT_ID, {
                eventCode: queryParams.get("evnt"),
            });

            if (result?.data?.data == 0) {
                loader("hide");
            }
            setEvent(result?.data?.data);
           
            const isInEventRegistration = window.location.pathname.includes("event");
            document.title = isInEventRegistration
                ? result?.data?.data?.title
                : "Docintel";
            setIsDataLoaded(true);
            const localData = "event_" + result?.data?.data?.id;
            if ((localStorage?.getItem(localData) == '' || localStorage?.getItem(localData) == null)) {
                fetchSiteData();
                setFirstPopupShow(true);
            }
            if(result?.data?.data?.live_status==0){
                loader("hide")
            }
        } catch (err) {
            setIsDataLoaded(true);
            console.log("-err", err);
        }
    };

    const handleClose = (e) => {
        setFirstPopupShow(e)
    }

    const fetchSiteData = async () => {
        try {
            const result = await getData(ENDPOINT.TRIALSITE);
            setSiteTrials(result?.data?.data);
        } catch (err) {
            console.log(err, 'err');
        }
    }

    return (
        <>
            <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
            {eventId != 0 && eventId?.live_status!=0? <div className="webinar-stream">
                <div className="container-fluid">
                    <div className="webinar-stream-top">
                        <div className="top-webinar-view">
                            <Row>
                                <Col lg={7} md={7} sm={12} xs={12} className="">
                                    <div className="site-logo">
                                        {/* <img src={path_image+'LEX210.png'} alt=""/> */}
                                        {/* <h2>LEX-210 Investigator Meeting | Milan 8 June 2024</h2> */}
                                        <h2>{eventId?.title?eventId?.title:""} | {eventId?.eventDateForLex?eventId?.eventDateForLex:""}</h2>
                                    </div>
                                </Col>
                                <Col lg={5} md={5} sm={12} xs={12} className="">
                                    <div className="user-info">
                                        <div className="user-name">
                                            <p>Welcome User</p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className="webinar-stream-content">
                        <div className="webinar-stream-content-inner">
                            {eventId.id != 0 && typeof eventId.id != "undefined" ? (
                                <>
                                    {!firstPopupShow && !cookieShow ? <WebinarPolls eventData={eventId} /> : null}
                                    <LiveStreaming handleEvent={setEvent} eventData={eventId} code={queryParams.get("evnt")} autoPopup={firstPopupShow} onClose={handleClose} siteTrials={siteTrials} setIsEventLive={setIsEventLive} />
                                </>
                            ) : isDataLoaded ? (
                                <CommonPageLinkNotFound />
                            ) : null}
                        </div>
                    </div>
                    <div className="webinar-stream-footer">
                        <div className="footer-detail">
                            <div className={firstPopupShow ? "privacy-link d-flex justify-content-between add_index" : "privacy-link d-flex justify-content-between"}>
                                <p>We only use essential cookies and no data is shared with 3rd party. <a href="javascript:void(0)" onClick={showCookieModal}>Click here</a> to see the specifics.</p>
                                <ul>
                                    <li><a href="https://albert.docintel.app/octapharma-trail-privacy/" target="_blank">Octapharma privacy statement</a></li>
                                    <li><a href="https://albert.docintel.app/privacy_policy/" target="_blank">Privacy policy</a></li>
                                    <li><a href="https://albert.docintel.app/terms_of_use/" target="_blank">Terms of service</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <Modal show={cookieShow}
                        onHide={hideCookieModal}
                        backdrop="static"
                        className="cookieadd" centered>
                        <Modal.Header closeButton>
                            <Modal.Title>Used cookies</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <h6>Essential cookies exclusively enhance your experience with us ensuring our system runs smoothly whilst recognising you for seamless recurring use. Be confident we never share your information with any commercial cookie company.</h6>
                            <div className="used-cookies-table-top">
                                <div className="used-cookies-table">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Purpose</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>eventQuestionLEX210 with dynamic number</td>
                                                <td>This cookie is used for storing the key respective of questions if questions is answered or not.</td>
                                            </tr>
                                            {/* <tr>
                                    <td>CAKEPHP</td>
                                    <td>Framework default cookie for cache of all component.</td>
                                </tr>
                                 <tr>
                                    <td>dynamic_number (ex 1210)</td>
                                    <td>This cookie is used for storing the status of consent given or not for the article.</td>
                                </tr>
                                <tr>
                                    <td>video_track</td>
                                    <td>This cookie is used for storing the last seek time of user for the particular video.</td>
                                </tr>
                                <tr>
                                    <td>name_use</td>
                                    <td>Used to autofill name of the user to help with repetitive task. Only used in some circumstances where user have been directed to the site based on previous consent. </td>
                                </tr>
                                <tr>
                                    <td>email_use</td>
                                    <td>Used to autofill email of the user to help with repetitive task. Only used in some circumstances where user have been directed to the site based on previous consent.</td>
                                </tr>
                                <tr>
                                    <td>country_use</td>
                                    <td>Used to autofill country of the user to help with repetitive task. Only used in some circumstances where user have been directed to the site based on previous consent.</td>
                                </tr>
                                <tr>
                                    <td>consent_type</td>
                                    <td>Used to detect if use have given full or limited consent to be sure tracking of usage is handled correctly.</td>
                                </tr> */}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            </div> : isDataLoaded && <CommonPageLinkNotFound />}

        </>
    );
};

export default Stream;
