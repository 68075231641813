export const InformedSurveyValidations = (data) => {
    let error = {};
    if (!data?.career) {
      error.career = "This field is required";
    }

    if (!data?.work_path) {
        error.work_path = "This field is required";
      }
    return error;
  };
  