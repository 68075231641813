import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from 'firebase/auth';
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyA88FEGjFrDuqHFZghzDIChHu3xk7tcv2Y",
  authDomain: "docintelpolls.firebaseapp.com",
  projectId: "docintelpolls",
  storageBucket: "docintelpolls.appspot.com",
  messagingSenderId: "82125078924",
  appId: "1:82125078924:web:6bdcc63abcd2b88baf96a9"
  };

// const firebaseConfig = {
//   apiKey: "AIzaSyC2_IAe4G4Y8U9D-27X3atKhUGWs1M1RuM",
//   authDomain: "triggers-ba47e.firebaseapp.com",
//   projectId: "triggers-ba47e",
//   storageBucket: "triggers-ba47e.appspot.com",
//   messagingSenderId: "743778002151",
//   appId: "1:743778002151:web:1077ccea556b954a906e42",
//   measurementId: "G-6DCCEYH7P1"
// };

// Initialize Firebase

const app = initializeApp(firebaseConfig);
// Export firestore database
// It will be imported into your react app whenever it is needed
export const db = getFirestore(app);
export const auth = getAuth(app);
export const database = getDatabase(app);