import { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import { ENDPOINT } from "../../../axios/apiConfig";
import { getData, postData } from "../../../axios/apiHelper";
import { loader } from "../../../loader";
import { toast } from "react-toastify";

let path_image = process.env.REACT_APP_ASSETS_PATH_INFORMED_DESIGN;
const AutoDisplayPopup = ({show, onClose, onData,trials}) => {

    const [userInputs, setUserInputs] = useState({});
    const [siteTrials, setSiteTrials] = useState({});
    const [error, setError] = useState({});

    useEffect(() => {
        fetchSiteData();
      }, [trials]);
    
    const fetchSiteData = async() => {
        setSiteTrials(trials);
        // try{
        //     const result = await getData(ENDPOINT.TRIALSITE);
        //     setSiteTrials(result?.data?.data);
        // }catch(err){
        //     console.log(err,'err');
        // }
    }

    const handleUserInput = (e, isSelectedName) => {
        setUserInputs({
            ...userInputs,
            [isSelectedName ? isSelectedName : e?.target?.name]: isSelectedName
              ? e?.target?.files
                ? e?.target?.files
                : e
              : e?.target?.value,
        });
    };

    const saveButtonClicked = async() => {
        setError({});
        if(!userInputs?.name || userInputs?.name?.trim() == ""){
            setError({"name":"Please enter your name"});
            return;
        }else if(!userInputs?.siteId){
            setError({"site":"Please select your site name/affiliation"});
            return;
        }else{
            try{
                loader("show")
                const response = await postData(ENDPOINT.SAVE_TRIAL_INFO,userInputs);
                onData(response?.data?.data);
                handleClose()
                loader("hide")
            }catch(error){
                console.log("error",error)
                toast.error(error)
                loader("hide")
            }
        }
    }

    const handleClose = () => {
        onClose(false)
    }

    return(
        <>
            <Modal show={show} backdrop="static" onHide={handleClose}  className="centered lex-log" keyboard={false} id="autoModal">
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                            <div className="site-logo">
                                {/* <img src={path_image+'LEX210.png'} alt=""/> */}
                                <h2>LEX-210</h2>
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                        <label htmlFor="">Please enter your name</label>
                        <input
                        type="text"
                        id="name"
                        onChange={(e) => handleUserInput(e)}
                        className={error?.name ? "form-control error" : "form-control"}
                        placeholder="Enter your name"
                        name="name"
                        />
                        {error?.name ? (
                            <div className="login-validation">{error?.name}</div>
                        ) : null}
                    </div>

                    <div className="form-group">
                        <label htmlFor="">Please select your site name/affiliation</label>
                        <Select
                        options={siteTrials}
                        onChange={(e) => handleUserInput(e?.value, "siteId")}
                        placeholder="Select your site name/affiliation"
                        className={error?.site ? "dropdown-basic-button split-button-dropup edit-production-dropdown error" : "dropdown-basic-button split-button-dropup edit-production-dropdown"}
                        isClearable
                        />
                        {error?.site ? (
                            <div className="login-validation">{error?.site}</div>
                        ) : null}
                    </div>

                    <button
                      className="btn btn-primary btn-filled next "
                      onClick={saveButtonClicked}
                    >
                      Save
                    </button>


                </Modal.Body>
            </Modal>
        </>
    );
};
export default AutoDisplayPopup;