export const LEXSurveyQuestionFormValidations = (data,remote_attendees=false) => {
  let error = {};


  if (!data?.investigator_meeting) {
    error.investigator_meeting = "This field is required";
  }
  if (!data?.presentations) {
    error.presentations = "This field is required";
  }
  if (!data?.investigator_meeting) {
    error.investigator_meeting = "This field is required";
  }
  if (!data?.strategical_and_operational) {
    error.strategical_and_operational = "This field is required";
  }
  if (!data?.discussion_session) {
    error.discussion_session = "This field is required";
  }
  if (!data?.technical_aspects && remote_attendees) {
    error.technical_aspects = "This field is required";
    
  }

  if (!data?.interesting_or_beneficial) {
    error.interesting_or_beneficial = "This field is required";
  }else{
    if (!data?.interesting_or_beneficial_input) {
      error.interesting_or_beneficial_input = "This field is required";
    }
  }

 
  
  

  if (!data?.feedback) {
    error.feedback = "This field is required";
  }else if(data?.feedback=="yes"){
    if (!data?.feedback_input) {
    error.feedback_input = "This field is required";
    }
  }else{
    
  }
  console.log(error);

  return error;
};

  