import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";

import {
  BrowserRouter as Router,
  Route,
  Routes,
  Outlet,
} from "react-router-dom";

import Event from "./Components/Firbase/Event";
import PollQuestion from "./Components/Firbase/PollQuestion";
import QuestionTrigger from "./Components/Firbase/QuestionTrigger";
import WebinarQuestion from "./Components/Firbase/WebinarQuestion";
import CommonPage from "./Components/Firbase/CommonPage";
import RegistrationPage from "./Components/NewWebinar/WebinarFiles/WebinarDashboard/Registration/RegistrationPage";
import Check8 from  "./Components/survey/Check8";
import SurveyQuestionForm from  "./Components/survey/SurveyQuestionForm";
import PageNotFound from "./Components/CommonComponents/PageNotFound"
import InformedSurvey from "./Components/survey/InformedSurvey";
import Stream from "./Components/Firbase/Stream";
import LiveQuestion from "./Components/Firbase/LiveQuestion";


if (
  window.location.pathname == "/new-webinar" ||
  window.location.pathname == "/contact-form" ||
  window.location.pathname == "/email-statsss" ||
  window.location.pathname == "/stats-webinar" ||
  window.location.pathname == "/event-create" ||
  window.location.pathname == "/setting-webinar"
) {
  require("./Components/assets/css/style.css");
  require("./Components/assets/fonts/fonts.css");

} else if (
  window.location.pathname == "/webinar" ||
  window.location.pathname == "/rd-register" ||
  window.location.pathname == "LEX-210-Registration"
) {
  require("./Components/assets/css/webinar.css");
  require("./Components/assets/css/webinar-responsive.css");
  require("./Components/assets/css/aos.css");

} else {
  require("./Components/assets/css/style.css?v=1.0");
  require("./Components/assets/css/responsive.css?v=1.0");
  require("./Components/assets/css/custom.css?v=1.0");
  require("./Components/assets/css/library.scss?v=1.0");
  require("./Components/assets/fonts/fonts.css?v=1.0");
  require("./Components/assets/css/video.css?v=1.0");
}

const Layout = () => {
  return (
    <>
      <CommonPage />
      <Outlet />
    </>
  );
};

  const Routing = () => {
    return (
      <Router>
        <Routes>
        <Route path="/stream" element={<Stream />} />
          <Route path="/event" element={<Event />} />
          <Route path="/event-registration" element={<RegistrationPage />} />
          <Route path="/survey/8check" element={<Check8 />} />
          <Route path="/survey/informed" element={<InformedSurvey />} />
          <Route path="/survey/survey-question-form" element={<SurveyQuestionForm />} />
          <Route exact path="/Webinar" element={<Layout />}>
            <Route exact path="poll-question" element={<PollQuestion />} />
            <Route exact path="question-list" element={<QuestionTrigger />} />
            <Route exact path="webinar-question" element={<WebinarQuestion />} />            
            <Route exact path="display-screen" element={<LiveQuestion />} />            
          </Route>
          <Route path="*" element={<PageNotFound />} />
        </Routes> 
      </Router> 
    );    
  }  

export default Routing;